export const getCart = ({ cartId }) =>
  new Promise((resolve, reject) => {
    fetch('/api/shop/cart?cartId=' + cartId, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.cart?.checkoutUrl) {
          resolve(data.cart)
        } else {
          reject('An error occured, cart returned from api not correct (getCart)')
        }
      })
      .catch(reject)
  })

export const createCart = ({ variantId, discountCode = null }) =>
  new Promise((resolve, reject) => {
    fetch('/api/shop/cart', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ variantId, quantity: 1, discountCode })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.cart?.checkoutUrl && data.cart) {
          resolve(data.cart)
        } else {
          reject('An error occured, cart returned from api not correct (createCart)')
        }
      })
      .catch(reject)
  })

export const addToCart = ({ cartId, variantId }) =>
  new Promise((resolve, reject) => {
    fetch('/api/shop/cart', {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cartId, variantId, quantity: 1 })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.cart?.checkoutUrl && data.cart) {
          resolve(data.cart)
        } else {
          reject('An error occured, cart returned from api not correct (addToCart)')
        }
      })
      .catch(reject)
  })

export const removeFromBag = ({ cartId, lineId }) =>
  new Promise((resolve, reject) => {
    fetch('/api/shop/cart', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cartId, lineId })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.cart?.checkoutUrl && data.cart) {
          resolve(data.cart)
        } else {
          reject('An error occured, cart returned from api not correct (removeFromBag)')
        }
      })
      .catch(reject)
  })

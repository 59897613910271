import { useState, useEffect, useContext } from 'react'
import { Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import { trackGoal } from 'fathom-client'
import { ShowNewsLetterContext } from 'context/popup'

export default function newsletterPopup() {
  const router = useRouter()
  //const [showPopup, setShowPopup] = useState(false)
  const [showPopup, setShowPopup] = useContext(ShowNewsLetterContext)
  const [pageView, setPageView] = useState(0)

  // Le but serait de mettre la popup uniquement quand va dans une deuxieme page et pas pour la premiere
  // State qui compte page vue dans la session, si >1 montrer popup

  useEffect(() => {
    if (pageView > 3) return
    if (router.pathname === '/contact') return
    setPageView((prev) => (prev += 1))
  }, [router])

  useEffect(() => {
    const rawitem = localStorage.getItem('popupDisabled')

    if (!rawitem) {
      if (pageView >= 2) {
        return showPopupTrue()
      } else {
        return setShowPopup(false)
      }
    }

    const item = JSON.parse(rawitem)
    const now = new Date()
    if (now.getTime() > item.expiry && pageView >= 2) {
      localStorage.removeItem('popupDisabled')
      return showPopupTrue()
    }

    return setShowPopup(false)
  }, [pageView])

  const showPopupTrue = () => {
    trackGoal('CV8ZQT4G', 0)
    return setShowPopup(true)
  }

  const closePopup = ({ completeForm = false }) => {
    setShowPopup(false)
    !completeForm && trackGoal('P1LPJNNN', 0)

    localStorage.setItem(
      'popupDisabled',
      JSON.stringify({
        value: true,
        expiry: new Date().setDate(new Date().getDate() + 10)
      })
    )
  }

  return (
    <Transition
      show={showPopup}
      className="fixed z-50 left-0 top-0 w-full h-screen"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-center justify-center h-screen">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 transition-opacity backdrop-blur backdrop-filter"
          aria-hidden="true">
          <div className="absolute inset-0 bg-yellow opacity-25" onClick={closePopup} />
        </Transition.Child>

        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className="relative p-12 w-full text-center bg-white shadow transform transition-all md:w-100"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <button
            onClick={closePopup}
            type="button"
            className="absolute left-6 top-6 p-2 text-yellow border border-yellow rounded-full focus:outline-none focus:ring-yellow focus:ring-offset-2 focus:ring-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 34 34"
              className="w-6 h-6 transform rotate-45"
              stroke="currentColor">
              <path strokeWidth={2} d="M32 17H17M17 17H2M17 17V2M17 17V32.5" />
            </svg>
          </button>

          <div className="flex justify-center mt-4">
            <img className="h-16" src="/logo.svg" />
          </div>
          <div className="mt-10 text-yellow font-serif text-6xl font-bold">Join Us</div>
          <div className="mt-6">
            <span className="font-bold">Abonnez-vous à notre newsletter</span> pour être tenu au courant des dernières
            tendances et actualités sur les réseaux sociaux et ne pas manquer nos prochaines publications !
          </div>

          <form
            onSubmit={() => {
              trackGoal('QQXHK6VV', 0)
              closePopup({ completeForm: true })
            }}
            action="https://ateliermilky.us1.list-manage.com/subscribe/post?u=1ea3323d482eda23798384205&amp;id=5dbcef87e1"
            method="post"
            target="_blank">
            <div className="flex items-center mt-4 h-10 bg-yellow-50">
              <input
                id="emailForm"
                type="email"
                name="EMAIL"
                required
                className="placeholder-gray-400 px-2 w-full h-full font-light tracking-wide bg-transparent rounded-none focus:outline-none focus:ring-yellow-300 focus:ring-2"
                placeholder="john.smith@domain.com"
              />
              <button
                type="submit"
                className="flex-0 w-10 h-10 focus:outline-none focus:ring-yellow-300 focus:ring-2"
                title="S'inscrire à la newsletter">
                →
              </button>
            </div>
          </form>

          <div className="mt-8 font-serif font-bold">Atelier Milky</div>
        </Transition.Child>
      </div>
    </Transition>
  )
}

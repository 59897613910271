import { useState, createContext, useEffect } from 'react'
import { useSWRConfig } from 'swr'

import { getCart } from 'lib/shop/cartCommon'

export const CartContext = createContext(false)
CartContext.displayName = 'Shop Cart'

export const ShopContextProvider = ({ children }) => {
  const [cartId, setCartId] = useState(null)
  const { mutate } = useSWRConfig()

  useEffect(() => {
    // Get cart id in local storage and see if legit
    const cartId_ls = localStorage.getItem('am_cartid')

    if (cartId_ls) {
      getCart({ cartId: cartId_ls })
        .then((cart) => {
          setCartId(cartId_ls)
          mutate('/api/shop/cart?cartId=' + cart.id, { cart: cart })
          return
        })
        .catch((error) => {
          localStorage.removeItem('am_cartid')
          return
        })
    }
  }, [])

  useEffect(() => {
    if (cartId) {
      getCart({ cartId })
        .then((cart) => {
          localStorage.setItem('am_cartid', cartId)
        })
        .catch((error) => {})
    }
  }, [cartId])

  return <CartContext.Provider value={[cartId, setCartId]}>{children}</CartContext.Provider>
}

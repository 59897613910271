import { useState, createContext } from 'react'

export const ShowNewsLetterContext = createContext(false)
ShowNewsLetterContext.displayName = 'Show Newsletter Popup'

export const ShowNewsLetterContextProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false)

  return <ShowNewsLetterContext.Provider value={[showPopup, setShowPopup]}>{children}</ShowNewsLetterContext.Provider>
}

import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as Fathom from 'fathom-client'
import TagManager from 'react-gtm-module'

import socialData from 'data/social'

import { ShowNewsLetterContextProvider } from '@/context/popup'
import { ShopContextProvider } from '@/context/cart'

import '../styles/fonts/butler/stylesheet.css'
import '../styles/tailwind.css'

import Newsletterpopup from 'components/newsletter/popup'

import Head from 'next/head'

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-KMB2QDR'
    })
    // Initialize Fathom when the app loads
    Fathom.load('SGGDSPHA', {
      url: 'https://toucan.ateliermilky.com/script.js',
      includedDomains: ['ateliermilky.com']
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  return (
    <>
      <Head>
        <meta property="theme-color" content="#fefcf7" />
        <meta property="language" content="FR" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <meta property="fb:page_id" content="106200308273098" />
        <meta name="facebook-domain-verification" content="qr0df2a1pu9ilgr2ejhxagz71ijzjl" />
        <meta property="og:site_name" content="Atelier Milky" />
        <meta name="p:domain_verify" content="fc2696407a470aea5e2d3b83793a6a03" />
        <meta name="twitter:dnt" content="on" />
        {socialData.twitterUsername && <meta name="twitter:site" content={`@${socialData.twitterUsername}`} />}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@id': 'https://ateliermilky.com#identity',
              '@type': 'Organization',
              name: 'Atelier Milky',
              image: {
                '@type': 'ImageObject',
                url: `${process.env.NEXT_PUBLIC_BASEPATH}/brand/largeLogo.png`,
                height: '585',
                width: '1170'
              },
              logo: {
                '@type': 'ImageObject',
                url: `${process.env.NEXT_PUBLIC_BASEPATH}/brand/logo.jpg`,
                height: '500',
                width: '500'
              },
              url: process.env.NEXT_PUBLIC_BASEPATH,
              sameAs: [
                socialData.twitterURL,
                socialData.facebookURL,
                socialData.instagramURL,
                socialData.pinterestURL
              ].filter(Boolean)
            })
          }}
        />

        {[
          '/fonts/butler/Butler-ExtraBold.ttf',
          '/fonts/butler/Butler-UltraLight.ttf',
          '/fonts/butler/Butler.ttf',
          '/fonts/butler/Butler-Bold.ttf',
          '/fonts/butler/Butler-Black.ttf',
          '/fonts/butler/Butler-Light.ttf',
          '/fonts/butler/Butler-Medium.ttf'
        ].map((thisLink) => (
          <link key={thisLink} rel="preload" href={thisLink} as="font" crossOrigin="" />
        ))}
        <link rel="preload" href="https://use.typekit.net/meh4sfo.css" as="style" />
      </Head>

      <ShowNewsLetterContextProvider>
        <ShopContextProvider>
          <Component {...pageProps} />
          <Newsletterpopup />
        </ShopContextProvider>
      </ShowNewsLetterContextProvider>
    </>
  )
}

export default MyApp
